import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import frontRoutes from "./parts/Front/router/index";
import config from "./config";
import NumericDirective from "./assets/libraries/numericDirectives";
import Notifications from "vue-notification";
import Multiselect from "vue-multiselect";
import moment from "moment-jalaali";
import adminRoutes from "./routes";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import jalali from "jalali-dayjs";
// import fa from "moment/src/locale/fa";
import Axios from "axios";
// axios.defaults.params = {}
// axios.defaults.params['XDEBUG_SESSION'] = 1
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import swal from "sweetalert";
import "./assets/libraries/veeValidate";
import "./libraries/directives";
import "./libraries/filters";
import "./assets/styles.css";
import "./assets/styles.scss";
import $ from "jquery/dist/jquery.js"; // dont use min !
import * as VueMethods from "./libraries/VueMethods";
import { FulfillingBouncingCircleSpinner } from "epic-spinners";
import store from "./libraries/store";
import "./libraries/core";
// import 'bootstrap' نباید لود بشه با ادمین - دیگه کار نمیکنه دراپ دان
import VueMeta from "vue-meta";
import VueMixin from "./libraries/VueMixin";
import VueNestable from "vue-nestable";
import "@/assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/swiper-bundle.css";
import ZoomOnHover from "vue-zoom-on-hover";
import VueDragscroll from "vue-dragscroll";
import pagination from "shetabit-laravel-vue-pagination";
// import { initializeApp } from '@firebase/app';
// import { getMessaging, onMessage, getToken   } from "@firebase/messaging";

import "./registerServiceWorker";
import axios from "axios";
import { getCookie } from "./libraries/VueMethods";
// import routes from 'admin-core-shop/src/core/routes'
// import notification from "@/parts/Front/components/notification/classes/notification.ts";
// let notif = new notification();
// console.log(notif.getNotification())
axios.defaults.baseURL = config.baseUrl;
axios.defaults.params = {};
axios.defaults.params.version = "new1";
if (VueMethods.isPart("admin")) {
  if (getCookie("adminAuthorization")) {
    axios.defaults.headers.common["Authorization"] = getCookie(
      "adminAuthorization"
    );
  }
}

Vue.use(ZoomOnHover);
Vue.use(VueNestable);
Vue.use(VueMeta);
Vue.use(NumericDirective);
window.swal = swal;
Vue.config.productionTip = false;
Vue.prototype.$axios = Axios;
Vue.prototype.$ = $;
window.$ = $;
Vue.prototype.moment = window.moment = dayjs;
Vue.prototype.access = require("safe-access");
dayjs.extend(jalali);
dayjs.extend(relativeTime);
dayjs.locale("fa");

Vue.component("date-picker", VuePersianDatetimePicker);
Vue.component("multiselect", Multiselect);
Vue.component("pagination", pagination);
Vue.component("Sketch", require("vue-color/src/components/Sketch.vue"));
Vue.component(
  "fulfilling-bouncing-circle-spinner",
  FulfillingBouncingCircleSpinner
);
// Vue.use(VueNumericInput);
Vue.use(Notifications);
Vue.use(VueRouter);
Vue.use(VueDragscroll);
import "@@/core/coreMain";

Vue.prototype.$store = store;

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    this.app.lastScrollPosition = { x: window.scrollX, y: window.scrollY };
    if (to.path === from.path) {
      return savedPosition;
    }
    // if (back) { return this.app.lastScrollPosition }

    return { x: 0, y: 0 };
  },
  mode: "history",
  routes: [...adminRoutes, ...frontRoutes],
});
export { router };

router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    console.log(store.state);
  }
  if (to.path === from.path && to.hash === "#") {
    return next(false);
  }
  if (
    to.path.includes("admin") &&
    !to.path.includes("login") &&
    !getCookie("adminAuthorization")
  ) {
    return next("/admin/login");
  }

  return next();
});

Vue.mixin(VueMixin);

const app = new Vue({
  data() {
    return {
      baseUrl: config.baseUrl,
      allPermissions: [],
      adminProfile: [],
      currentAdminPermissions: [],
      transitionName: "drain",
      ckeditorLoaded: false,
      lastScrollPosition: { x: 0, y: 0 },
      pushToken: null,
      onPushTokenReceivedCallbacks: [], // زمانی که از مرورگر خوند
      query: {
        meta_title: "",
      },
    };
  },
  computed: {
    categoryContent() {
      if (this.$store.getters["front/getCategoryContent"] != null) {
        console.log(this.$store.getters["front/getCategoryContent"], "mainjs");
      }
      return this.$store.getters["front/getCategoryContent"];
    },
  },
  created() {
    console.log(this.$route);
    console.log(this.categoryContent, "category content");
    // const firebaseApp = initializeApp(config.firebase)
    // const messaging = getMessaging(firebaseApp);
    // getToken(messaging,
    //     { vapidKey: config.firebase.vapidKey })
    //     .then((currentToken) => {
    //     if (currentToken) {
    //         // Send the token to your server and update the UI if necessary
    //         this.havePermission='accepted'
    //         this.pushToken = currentToken;
    //         this.onPushTokenReceivedCallbacks.forEach(callback => {
    //             callback(currentToken);
    //         });
    //         console.log(currentToken)
    //     } else {
    //         // Show permission request UI
    //         console.log('No registration token available. Request permission to generate one.');
    //         // ...
    //     }
    // }).catch((err) => {
    //     console.log('An error occurred while retrieving token. ', err);
    //     // ...
    // });

    // onMessage(messaging, (payload) => {
    //     console.log('Message received. ', payload);
    //     // ...
    // });

    // _messaging.onMessage(payload => {
    //     console.log(payload)
    //     const notification = payload.notification
    //     setTimeout(() => {
    //         const notificationOptions = {
    //             title: notification.title,
    //             body: notification.body,
    //             icon: notification.image || '/images/logo.png',
    //             click_action: notification.click_action,
    //             actions: [{
    //                 title: 'برو به چت',
    //                 action: 'goToChat'
    //             }],
    //             data: notification
    //         };
    //         navigator.serviceWorker.ready
    //             .then(registration => {
    //                 registration.showNotification(notificationOptions.title, {
    //                     body: notificationOptions.body,
    //                     icon: notificationOptions.icon,
    //                     actions: notificationOptions.actions,
    //                     data: {
    //                         data: notificationOptions.data,
    //                     }
    //                 });
    //             })
    //     }, 500)
    //
    // });
    this.$axios.defaults.baseURL = config.baseUrl;
    this.$axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.error(error);
        if (this.$axios.isCancel(error)) {
          console.log("Axios canceled");
          throw error;
        }
        if (error.response.status === 401) {
          this.$notify({
            group: "error",
            title: "پیام سیستم",
            text: "توکن منقضی شده. لطفا مجددا وارد شوید",
            type: "error",
            duration: "5000",
          });
          if (VueMethods.isPart("admin")) {
            VueMethods.deleteCookie("adminAuthorization");
            router.push("/admin/login");
          } else {
            VueMethods.deleteCookie("Authorization");
            this.$store.commit("front/setLoginStatus", false);
            router.push("/");
          }
        }

        throw error;
      }
    );

    if (VueMethods.isPart("admin")) {
      if (this.getCookie("adminAuthorization")) {
        this.$axios.defaults.headers.common["Authorization"] = this.getCookie(
          "adminAuthorization"
        );
      } else {
        router.push("/admin/login");
      }
    }
    if (VueMethods.isPart("") && !VueMethods.isPart("admin")) {
      let authorization = this.getCookie("Authorization");
      if (authorization) {
        this.$axios.defaults.headers.common["Authorization"] = authorization;
        this.$store.commit("front/setLoginStatus", true);
      }
    }
  },
  methods: {
    ...VueMethods,
    onPushTokenReceived(callback) {
      if (this.pushToken) {
        callback(this.pushToken);
      } else {
        this.onPushTokenReceivedCallbacks.push(callback);
      }
    },
  },

  watch: {
    $route(to) {
      let title = config.title;
      if (to.params.slug ) {
        
        title += " - " + to.params.slug.replaceAll("-", " ");
    } else if (this.meta.title) {
        title += " - " + to.meta.title;
      }
      document.title = title;
      setTimeout(() => {
        document.title = title;
      } , 0);
    },

    "$store.state.front.1.homeData.user.cart": {
      handler: function(cart, oldCart) {
        cart.updateCookieIfNeeded();
      },
      deep: true,
    },
  },
  render: (h) => h(App),
  router: router,
}).$mount("#app");

window.app = app;
